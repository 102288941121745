<template>
  <div class="modal fade" id="addProductSerialModal" tabindex="-1" role="dialog" aria-labelledby="addProductSerialModal"
       aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white text-capitalize">{{ selectedProduct.name }}</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetModal()">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table id="users-list-datatable" class="table">
              <thead>
              <tr>
                <th class="position-relative" :style="{width:'20%'}">
                  SERIAL
                  <div class="position-absolute sorting-up-button">
                    <i class='bx bx-chevron-up'></i>
                  </div>
                  <div class="position-absolute sorting-down-button">
                    <i class='bx bx-chevron-down'></i>
                  </div>
                </th>
                <th class="position-relative" :style="{width:'18%'}">
                  MODEL
                  <div class="position-absolute sorting-up-button">
                    <i class='bx bx-chevron-up'></i>
                  </div>
                  <div class="position-absolute sorting-down-button">
                    <i class='bx bx-chevron-down'></i>
                  </div>
                </th>
                <th class="position-relative" :style="{width:'12%'}">
                  SKU
                  <div class="position-absolute sorting-up-button">
                    <i class='bx bx-chevron-up'></i>
                  </div>
                  <div class="position-absolute sorting-down-button">
                    <i class='bx bx-chevron-down'></i>
                  </div>
                </th>
                <th class="position-relative" :style="{width:'20%'}">
                  BAR CODE
                  <div class="position-absolute sorting-up-button">
                    <i class='bx bx-chevron-up'></i>
                  </div>
                  <div class="position-absolute sorting-down-button">
                    <i class='bx bx-chevron-down'></i>
                  </div>
                </th>
                <th class="position-relative" :style="{width:'15%'}">
                  QR CODE
                  <div class="position-absolute sorting-up-button">
                    <i class='bx bx-chevron-up'></i>
                  </div>
                  <div class="position-absolute sorting-down-button">
                    <i class='bx bx-chevron-down'></i>
                  </div>
                </th>
                <!--                <th class="position-relative" :style="{width:'10%'}">
                                  M. CODE
                                  <div class="position-absolute sorting-up-button">
                                    <i class='bx bx-chevron-up'></i>
                                  </div>
                                  <div class="position-absolute sorting-down-button">
                                    <i class='bx bx-chevron-down'></i>
                                  </div>
                                </th>-->
                <th :style="{width:'5%'}">
                  ACTIONS
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(productItem, index) in productItemsData" :key="index">
                <td>{{ productItem.serial_number }}</td>
                <td>{{ productItem.model_number }}</td>
                <td>{{ productItem.sku }}</td>
                <td>{{ productItem.qr_code }}</td>
                <td>{{ productItem.barcode }}</td>
                <!--                <td>{{ productItem.manufacturing_code }}</td>-->
                <td><input type="checkbox" v-model="checkedItem" :value="productItem.id"/></td>
              </tr>
              </tbody>
            </table>
          </div>
          <hr>
<!--          <div>{{ checkedItem }}</div>-->
        </div>
        <div class="modal-footer border-0 pt-0">
          <button v-if="isProductModalShow" type="button" class="btn btn-danger" data-toggle="modal"
                  data-target="#productItemAddModal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Add Product Item</span>
          </button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block" @click="resetModal()">Close</span>
          </button>
          <button type="button" class="btn btn-primary" @click="addItemSerial">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Add Serial</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <AddProductItemModal @getCreatedData="createdData($event,data)" :product-id="selectedProduct.id" :state-id="selectedState" :condition-id="selectedCondition"/>
</template>

<script>
import AddProductItemModal from "@/views/backEnd/Orders/includes/AddProductItemModal";

export default {
  name: "AddProductSerialModal",
  components: {
    AddProductItemModal,

  },
  props: ['productItems', 'cartItemQuantity', 'addedItems', 'selectedProduct', 'selectedState', 'selectedCondition'],
  emits: ['getSelectedItemId'],
  data() {
    return {
      checkedItem: [],
      isProductModalShow: false,
      productItemsData: [],
    }
  },
  watch: {
    productItems(productItems) {
      this.productItemsData = productItems;
      this.productItemsCount();
    },
    
    // productItemsData(productItemsDataCurrent,productItemsDataPrevious){
    //   if (productItemsDataCurrent > pro) {
        
    //   }
    // }
  },
  computed: {},
  methods: {
    addItemSerial() {
      this.$emit('getSelectedItemId', this.checkedItem)
    },
    createdData(data) {
      this.productItemsData.push(data);
      this.productItemsCount();
      console.log(this.productItemsData.length)
    },
    async productItemsCount() {
      let availableItem = this.productItems.reduce((counter) => counter += 1, 0);
      //console.log(availableItem)
      if (availableItem < this.cartItemQuantity) {
        console.log('inside')
        this.isProductModalShow = true
      }
    },
    resetModal() {
      Object.assign(this.$data, this.$options.data.apply(this));
    }
  }

}
</script>

<style>

</style>
