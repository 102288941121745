<template>
<div class="modal fade " id="addProcureProductItemModal" tabindex="-1" role="dialog" aria-labelledby="addProcureProductItemModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Add Item</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clear_data">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <h5 class="my-1 text-uppercase">Basic Info</h5>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Select Product</label>
                                    <VueMultiselect :allow-empty="false" v-model="selectedProduct" class="multiselect-blue" :options="productOptions" :close-on-select="true" placeholder="Select Status" label="name" track-by="name" :show-labels="false" :disabled="true" />

                                </div>
                                <div class="text-danger" v-if="errors.product_id">{{ errors.product_id }}</div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Select Warehouse</label>
                                    <VueMultiselect :allow-empty="false" v-model="selectedWarehouse" class="multiselect-blue" :options="warehouseOptions" :close-on-select="true" placeholder="Select Status" label="name" track-by="name" :show-labels="false" />
                                </div>
                                <div class="text-danger" v-if="errors.warehouse_id">{{ errors.warehouse_id }}</div>
                            </div>

                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="">
                                    <label>Serial No</label>
                                    <input v-model="postProductItemData.serial_number" type="text" class="form-control" placeholder="Enter Serial No">
                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="">
                                    <label>Model No</label>
                                    <input v-model="postProductItemData.model_number" type="text" class="form-control" placeholder="Enter Model No">
                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="">
                                    <label>SKU</label>
                                    <input v-model="postProductItemData.sku" type="text" class="form-control" placeholder="Enter SKU">
                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="">
                                    <label>Bar Code</label>
                                    <input v-model="postProductItemData.barcode" type="text" class="form-control" placeholder="Enter Bar Code">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="">
                                    <label>QR Code</label>
                                    <input v-model="postProductItemData.qr_code" type="text" class="form-control" placeholder="Enter QR Code">
                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="">
                                    <label>Manufacturing Code</label>
                                    <input v-model="postProductItemData.manufacturing_code" type="text" class="form-control" placeholder="Enter Manufacturing Code">
                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Select State</label>
                                    <VueMultiselect :allow-empty="false" v-model="selectedState" class="multiselect-blue" :options="stateOptions" :close-on-select="true" placeholder="Select Status" label="name" track-by="name" :show-labels="false" :disabled="true" />
                                </div>
                                <div class="text-danger" v-if="errors.state">{{ errors.state }}</div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Select Vendor</label>
                                    <VueMultiselect :allow-empty="false" v-model="selectedVendor" class="multiselect-blue" :options="vendorOptions" :close-on-select="true" placeholder="Select Status" label="name" track-by="name" :show-labels="false" />
                                </div>
                                <div class="text-danger" v-if="errors.vendor_id">{{ errors.vendor_id }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="">
                                    <label>Purchase Price</label>
                                    <input v-model="postProductItemData.purchase_cost" type="number" class="form-control" placeholder="Enter Purchase Cost">
                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Status</label>
                                    <VueMultiselect :allow-empty="false" v-model="selectedCondition" class="multiselect-blue" :options="conditionOptions" :close-on-select="true" placeholder="Select Status" label="name" track-by="name" :show-labels="false" :disabled="true" />
                                </div>

                            </div>
                        </div>
                        <div class="col-12">
                            <h5 class="my-1 text-uppercase"> Licence Info </h5>
                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <div class="form-group">
                                        <fieldset class="mt-2">
                                            <div class="checkbox checkbox-primary">
                                                <input type="checkbox" class="" id="hasLicense" v-model="hasLicense">
                                                <label for="hasLicense">Has Licence</label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div v-if="hasLicense" class="col-md-10 d-flex">
                                    <div class="col-12 col-md-4">
                                        <div class="form-group">
                                            <div class="controls">
                                                <label>Licence Type</label>
                                                <VueMultiselect :allow-empty="false" v-model="selectedLicenceType" class="multiselect-blue" :options="licenceTypeOptions" :close-on-select="true" placeholder="Select Licence type" label="name" track-by="name" :show-labels="false" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="form-group">
                                            <div class="controls">
                                                <label>Period</label>
                                                <VueMultiselect v-model="selectedPeriod" :allow-empty="false" class="multiselect-blue" :options="periodOptions" :close-on-select="true" placeholder="Select Period" label="name" track-by="name" :show-labels="false" />
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="form-group">
                                            <div class="">
                                                <label>Licence Number</label>
                                                <input v-model="postProductLicenseData.license_key" type="text" class="form-control" placeholder="Enter licence Number">
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12" v-if="$route.name === 'appOrderProcure'">
                            <button @click="productItemList.push({name: '',base64Image: ''})" class="btn btn-sm btn-primary text-uppercase d-flex align-items-center "><span class="bx-flashing">Add Item</span></button>
                            <div class="form-group">

                            </div>
                        </div>
                        <div class="col-12" v-for="(item,index) in productItemList" :key="index">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <input type="text" v-model="item.name" class="form-control" placeholder="Enter name" required>
                                    </div>
                                    <div v-if="item?.base64Image" class="col-sm-4">
                                        <img :src="item?.base64Image?? ''" alt="customer-signature" class="img-fluid" @click="openImageModal(item.base64Image)">
                                    </div>

                                    <div  class="col-sm-4">
                                        <div class="d-flex">
                                            <input type="file" :id="index" @change="setImage" class="form-control">
                                            <button @click="removeProductItem(index)" class="btn btn-sm text-danger ml-1 px-0"><i class='bx bx-x'></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <div v-if="$route.name === 'appOrderView'">
                    <button type="button" @click="processProductItem({status: true})" class="btn btn-success">
                        <i class="bx bx-x d-block d-sm-none"></i>
                        <span class="d-none d-sm-block">Accept</span>
                    </button>&nbsp;
                    <button type="button" @click="processProductItem({status: false})" class="btn btn-warning">
                        <i class="bx bx-x d-block d-sm-none"></i>
                        <span class="d-none d-sm-block">Declined</span>
                    </button>
                </div>
                <div v-else>
                    <button type="button" @click="processHandler()" class="btn btn-primary">
                        <i class="bx bx-x d-block d-sm-none"></i>
                        <span class="d-none d-sm-block">Submit</span>
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>
<ImageShowModal :image-source-obj="selectedImage" modal-name="showImageModal" />
<div class="" data-toggle="modal" data-target="#showImageModal"></div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import {
    mapActions,
    mapGetters
} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import ImageShowModal from "@/components/backEnd/modal/ImageShowModal";

export default {
    name: "AddProcureProductItemModal",
    props: ['productData', 'vendorList', 'parentIndex', 'childIndex', 'procureProduct', 'isFranchiseeDataSend', 'franchiseeId'],
    mixins: [ShowToastMessage, Loader],
    emits: ['productItemObj'],
    components: {
        VueMultiselect,
        ImageShowModal,
    },
    data() {
        return {
            selectedImage: {
                imageSource: ''
            },
            hasLicense: false,
            licence_number: '',
            selectedLicenceType: {
                value: '',
                name: 'None'
            },
            selectedPeriod: {
                value: '',
                name: 'None'
            },
            licenceTypeOptions: [{
                    value: '',
                    name: 'None'
                },
                {
                    value: 0,
                    name: 'Subscription'
                },
                {
                    value: 1,
                    name: 'Period'
                }
            ],
            periodOptions: [{
                    value: '',
                    name: 'None'
                },
                {
                    value: '+7 days',
                    name: '7 Days'
                },
                {
                    value: '+15 days',
                    name: '15 Days'
                },
                {
                    value: '+30 days',
                    name: '30 days'
                },
                {
                    value: '+3 months',
                    name: '3 months'
                },
                {
                    value: '+6 months',
                    name: '6 months'
                },
                {
                    value: '+1 year',
                    name: '1 year'
                },
                {
                    value: '+2 years',
                    name: '2 years'
                },
                {
                    value: '+3 years',
                    name: '3 years'
                },
            ],

            postProductLicenseData: {
                customer_id: '',
                product_item_id: '',
                license_type: '',
                period: '',
                license_key: '',
            },
            postProductItemData: {
                product_id: '',
                serial_number: '',
                model_number: '',
                sku: '',
                barcode: '',
                qr_code: '',
                manufacturing_code: '',
                purchase_cost: '',
                condition: '',
                warehouse_id: '',
                state: '',
                vendor_id: '',
            },
            selectedProduct: {
                value: '',
                name: 'None'
            },
            selectedCondition: {
                value: '',
                name: 'None'
            },
            getSettingsParams: {
                type: ['product_item', 'default'],
                key: ['product_item_condition', 'default_state'],
            },
            selectedWarehouse: {
                value: '',
                name: 'None'
            },
            selectedState: {
                value: '',
                name: 'None'
            },
            selectedVendor: {
                value: '',
                name: 'None'
            },
            selectedProductId: '',
            errors: {
                product_id: '',
                vendor_id: '',
                warehouse_id: '',
                state: '',
            },
            productItemList: [{
                name: '',
                base64Image: '',
                originalImage: '',

            }],
            franchiseeDataSend: this.isFranchiseeDataSend ?? false,
            isAdminProcessed: false,
            isProcureItem: false,

        }
    },
    watch: {
        productData(productData) {
            let productId = productData.product.id;
            let conditionId = productData.condition.id;
            let stateId = productData.state;
            if (productId) {
                this.selectedProduct = this.productOptions.find(({
                    value
                }) => value === productId);
            }
            if (conditionId) {
                this.selectedCondition = this.conditionOptions.find(({
                    value
                }) => value === conditionId);
            }
            if (stateId) {
                this.selectedState = this.stateOptions.find(({
                    value
                }) => value === stateId);
            }
        },
        procureProduct(procureProduct) {
            /*this.postProductItemData.product_id = '';
            this.postProductItemData.condition = '';
            this.postProductItemData.state = '';*/
            if (!this.isEmptyObject(procureProduct)) {
                this.postProductItemData.serial_number = procureProduct.productItemObj.serial_number;
                this.postProductItemData.model_number = procureProduct.productItemObj.model_number;
                this.postProductItemData.sku = procureProduct.productItemObj.sku;
                this.postProductItemData.barcode = procureProduct.productItemObj.barcode;
                this.postProductItemData.qr_code = procureProduct.productItemObj.qr_code;
                this.postProductItemData.manufacturing_code = procureProduct.productItemObj.manufacturing_code;
                this.postProductItemData.purchase_cost = procureProduct.productItemObj.purchase_cost;
                this.selectedWarehouse = this.warehouseOptions.find(({
                    value
                }) => value === procureProduct.productItemObj.warehouse_id);
                this.selectedVendor = "";
                this.selectedVendor = this.vendorOptions.find(({value}) => value === procureProduct.productItemObj.vendor_id);

                this.hasLicense = procureProduct.hasLicense;
                this.isProcureItem = procureProduct?.isProcureItem ?? false;
                this.isAdminProcessed = procureProduct?.isAdminProcessed ?? false;
                this.postProductLicenseData.customer_id = procureProduct.productLicenseObj.customer_id;
                this.postProductLicenseData.product_item_id = procureProduct.productLicenseObj.product_item_id;
                this.selectedLicenceType = this.licenceTypeOptions.find(({
                    value
                }) => value === procureProduct.productLicenseObj.license_type);
                this.selectedPeriod = this.periodOptions.find(({
                    value
                }) => value === procureProduct.productLicenseObj.period);
                this.postProductLicenseData.license_key = procureProduct.productLicenseObj.license_key;

                this.productItemList = procureProduct ?.productImageObj ?? [{
                    name: '',
                    base64Image: ''
                }];
            }

        },

        selectedCondition() {
            this.postProductItemData.condition = this.selectedCondition.value;
        },
        selectedProduct() {
            this.postProductItemData.product_id = this.selectedProduct.value;
        },
        selectedWarehouse() {
            this.postProductItemData.warehouse_id = this.selectedWarehouse.value;
        },
        selectedState() {
            this.postProductItemData.state = this.selectedState.value;
        },
        selectedVendor() {
            this.postProductItemData.vendor_id = this.selectedVendor?.value ?? null;
        },
        selectedLicenceType() {
            this.postProductLicenseData.license_type = this.selectedLicenceType.value;
        },
        selectedPeriod() {
            this.postProductLicenseData.period = this.selectedPeriod.value;
        },
    },
    computed: {
        ...mapGetters({
            previousRoute: 'previousRoute',

        }),
        productOptions() {
            let products = this.$store.getters['appProducts/products'].map((type) => {
                let id = type.id;
                let name = type.name;
                return {
                    value: id,
                    name: name
                };
            });
            return [{
                value: '',
                name: 'None'
            }, ...products];
        },
        conditionOptions() {
            let condition = this.$store.getters['appSettings/settingProductItemCondition'].value.map((item) => {
                let value = item.value;
                let name = item.name;
                return {
                    value: value,
                    name: name
                };
            });
            return [{
                value: '',
                name: 'None'
            }, ...condition];
        },
        stateOptions() {
            let states = this.$store.getters['appSettings/settingDefaultState'].value;
            return [{
                value: '',
                name: 'None'
            }, ...states];
        },
        warehouseOptions() {
            let warehouses = this.$store.getters['appWarehouses/warehouses'].map((warehouse) => {
                let id = warehouse.id,
                    name = warehouse.name;
                return {
                    value: id,
                    name: name
                };
            })
            return [{
                value: '',
                name: 'None'
            }, ...warehouses]
        },
        vendorOptions() {
            let vendors = this.$store.getters['appVendors/vendors'].map((vendor) => {
                let id = vendor.id,
                    first_name = vendor ?.user ?.first_name ?? '',
                    last_name = vendor ?.user ?.last_name ?? '',
                    code = vendor.code;
                return {
                    value: id,
                    name: `${first_name} ${last_name} -code: ${code}`
                };
            })
            return [{
                value: '',
                name: 'None'
            }, ...vendors]
        },
        isProductLicensesDataFilled() {
            return !!(this.hasLicense && this.postProductLicenseData.license_type !== null && this.postProductLicenseData.period && this.postProductLicenseData.license_key);
        },
    },
    methods: {
        ...mapActions({
            getSettings: 'appSettings/getSettings',
            getProducts: 'appProducts/getProducts',
            getWarehouses: 'appWarehouses/getWarehouses',
            getVendors: 'appVendors/getVendors',

            postProductItem: 'appProductItems/postProductItem',
            postProductLicense: 'appProductLicenses/postProductLicense',
        }),
        isEmptyObject(value) {
            return Object.keys(value).length === 0 && value.constructor === Object;
        },
        async createProductItemLicenses(dataObj) {
            this.loader(true);
            return await this.postProductLicense(dataObj).then((response) => {
                if (response.status === 201) {
                    this.loader(false);
                    return true;
                } else {
                    this.loader(false);
                    return false;
                }
            });
        },

        async getSettingList(settingParams) {
            await this.getSettings(settingParams);
        },
        async singlePostProductItem() {
            await this.postProductItem({
                ...this.postProductItemData,
                purchase_cost: this.postProductItemData.purchase_cost * 100,
                purchaser: this.franchiseeId,
            }).then(async (response) => {
                // console.log(response)
                if (response.status === 201) {
                    const toastObj = {
                        message: 'Product Item created successful.',
                        type: 'success'
                    };
                    this.showToastMessage(toastObj);
                    const productItem = this.$store.getters['appProductItems/productItem']
                    // create product license
                    if (this.isProductLicensesDataFilled) {
                        this.postProductLicenseData.product_item_id = productItem.id;
                        await this.createProductItemLicenses(this.postProductLicenseData);
                    }
                    const productLicense = this.$store.getters['appProductLicenses/productLicense']

                    this.$emit('productItemObj', {
                        parentIndex: this.parentIndex,
                        childIndex: this.childIndex,
                        procureObj: {
                            productItemObj: productItem,
                            productLicenseObj: productLicense,
                            hasLicense: this.hasLicense,
                        },
                        status: 2
                    });
                }
                if (response.message) {
                    this.showToastMessage(response);
                }
                this.errors.product_id = response ?.errors ?.product_id ?. [0] ?? '';
                this.errors.vendor_id = response ?.errors ?.vendor_id ?. [0] ?? '';
                this.errors.warehouse_id = response ?.errors ?.warehouse_id ?. [0] ?? '';
                this.errors.state = response ?.errors ?.state ?. [0] ?? '';
            });
        },
        clear_data() {
            this.isProcureItem = false;
            this.isAdminProcessed = false;
            this.productItemList = [{
                name: '',
                base64Image: '',
                originalImage: '',

            }],
            this.selectedWarehouse = {
                value: '',
                name: 'None'
            };
            this.selectedVendor = {
                value: '',
                name: 'None'
            };
            this.postProductItemData.vendor_id = '';
            this.postProductItemData.warehouse_id = '';
            this.postProductItemData.serial_number = '';
            this.postProductItemData.model_number = '';
            this.postProductItemData.sku = '';
            this.postProductItemData.barcode = '';
            this.postProductItemData.qr_code = '';
            this.postProductItemData.manufacturing_code = '';
            this.postProductItemData.purchase_cost = '';
            this.selectedLicenceType = {
                value: '',
                name: 'None'
            };
            this.selectedPeriod = {
                value: '',
                name: 'None'
            };
            this.postProductLicenseData.license_key = '';
        },
        // procure product
        async processProductItem({status}) {
            if (status) {
                await this.singlePostProductItem();
            } else {
                this.$emit('productItemObj', {
                    parentIndex: this.parentIndex,
                    childIndex: this.childIndex,
                    procureObj: {},
                    status: 3
                });
            }
        },

        openImageModal(imgSource) {
            this.selectedImage.imageSource = imgSource;
            document.querySelector('[data-target="#showImageModal"]').click();
        },

        setImage(event) {
            this.loader(true);
            if (event.target.files.length > 0 && event.target.files[0].type.match('image.*')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.productItemList[event.target.id].base64Image = e.target.result;
                    event.target.value = '';

                };
                reader.readAsDataURL(event.target.files[0]);

            } else {

                this.showToastMessage({
                    type: "error",
                    message: 'Please upload image file(png,jpg,jpeg) only'
                });
            }
            this.loader(false);
        },

        async processHandler() {
            this.loader(true);
            const status = this.productItemList.every((singleItem) => Boolean(singleItem.name) && singleItem.base64Image.length > 0);
            if (status) {
                let productItemObj = {
                    productImageObj: this.productItemList,
                    productItemObj: this.postProductItemData,
                    productLicenseObj: this.postProductLicenseData,
                    hasLicense: this.hasLicense,
                };
                this.$emit('productItemObj', {
                    parentIndex: this.parentIndex,
                    childIndex: this.childIndex,
                    procureObj: productItemObj,
                    status: 1
                });
                this.resetData();
            }
            this.loader(false);

        },

        removeProductItem(index) {
            if (this.productItemList.length > 1) {

                this.productItemList = this.productItemList.filter((_, id) => id !== index)
            }
        },

        resetData() {
            this.productItemList = [{
                name: '',
                base64Image: '',
                originalImage: '',

            }],
            Object.assign(this.$data, this.$options.data.apply(this));
            this.errors = {}
        }

    },
    async mounted() {
        /* await this.getProducts();
         await this.getSettingList(this.getSettingsParams);
         await this.getWarehouses();
         await this.getVendors({with_relation: ['user']});*/
    }
}
</script>

<style scoped>

</style>
