<template>
  <div class="modal fade text-left" :id="modalName" tabindex="-1" role="dialog" :aria-labelledby="modalName" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-transparent">
          <h5 class="modal-title white" id=""></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center mx-2">
            <img :src="imageSourceObj.imageSource" alt="image source" class="img-fluid">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageShowModal',
  props: {
    modalName: {
      type: String,
      default: "imageShowModal",
      required: true,
    },
    imageSourceObj: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>
